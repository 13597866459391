import { defineAsyncComponent } from 'vue';
import { defineAEMCustomElement } from '@dh-io-emh/shared/src/utils/defineAEMCustomElement.util';

const TAG_NAME = 'emh-seo-text';

defineAEMCustomElement({
  componentTag: TAG_NAME,
  MainComponent: defineAsyncComponent(() => import('./App.vue')),
  LoadingComponent: defineAsyncComponent(
    () => import('@dh-io-emh/shared/src/components/loading/loadingSpinner/index.vue'),
  ),
});
